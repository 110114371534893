import request from '../../utils/request'

//获取评估标准接口分页
export function getMedicalList(data: any): Promise<any> {
  return request({
    method: 'GET',
    data: data,
    type: 'params',
    url: 'manage/reportRegist/list'
  })
}

// 根据id获取详情页面
export function getOneDetail(id: number): Promise<any> {
  return request({
    method: 'GET',
    url: 'manage/reportRegist/detail/' + id
  })
}

//查看报告签名
export function getSignature(id: number): Promise<any> {
  return request({
    method: 'GET',
    url: 'manage/reportRegist/detail/' + id
  })
}