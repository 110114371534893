














































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { ElForm } from "element-ui/types/form";
import { getTenantData } from "@/serve/medicalAppointment/institutionManage";
import {
  getMedicalList,
  getOneDetail,
  getSignature,
} from "@/serve/medicalReport/medicalReport";
interface Data {
  current?: number;
  size?: number;
  [propName: string]: any;
}
interface Detail {
  [propName: string]: any;
}
@Component({
  name: "MedicalReport",
})
export default class MedicalReport extends Vue {
  public currentPage4 = 1;
  public form = {
    checkerName: "",
    current: 1,
    size: 10,
    checkerIdCard: "",
    checkStartTime: "",
    checkEndTime: "",
    tenantId: "",
  };
  public detailList = {
    jkglChecker: {},
    fileUrlList: [],
  };
  public date = [];
  public tableData = [];
  public dialogFormVisible = false;
  public arrDetailList: Detail[] = [];
  public isAdmin = sessionStorage.getItem("isAdmin");
  public tenantName = "";
  public restaurants = [];
  public created() {
    this.getReportList();
  }
  public mounted() {
    this.getTenantData();
  }
  /**获取全部租户 */
  public async getTenantData(): Promise<void> {
    const res = await getTenantData({ size: 100000, current: 1 });
    this.restaurants = res.data.records;
  }
  public handleSelect(e: any) {
    if (Object.keys(e).length > 0) this.form.tenantId = e.tId;
    else this.form.tenantId = "";
  }
  public async querySearch(queryString: any, cb: any) {
    const restaurants = this.restaurants;
    const newArr: any = [];
    restaurants.forEach((x: any) => {
      if (x.name.includes(queryString)) {
        newArr.push({ value: x.name, tId: x.id });
      }
    });
    // 调用 callback 返回建议列表的数据
    cb(newArr);
  }
  // 获取数据接口
  public async getReportList() {
    // 如果form的数据有'' 则赋值为null 由于后端也会查询''类型的值
    for (const key in this.form) {
      if ((this.form as Data)[key] === "") {
        (this.form as Data)[key] = null;
      }
    }
    const res = await getMedicalList(this.form);
    this.tableData = await res.data;
    console.log("渲染表格数据", this.tableData);
  }
  // 重置功能
  public resetForm(formName: string) {
    (this.$refs[formName] as ElForm).resetFields();
    this.currentPage4 = 1;
    this.date = [];
    this.form.checkEndTime = "";
    this.form.checkStartTime = "";
    this.getReportList();
  }
  // 筛选按钮
  public onSubmit() {
    if (this.date) {
      // 绑定的date的值 然后是个数组赋值给form
      this.form.checkStartTime = this.date[0];
      this.form.checkEndTime = this.date[1];
    } else {
      this.form.checkStartTime = "";
      this.form.checkEndTime = "";
    }
    // 点击筛选后 初始化值
    this.currentPage4 = 1;
    this.form.size = 10;
    this.form.current = 1;
    this.getReportList();
  }
  // 详情按钮点击
  public async readMore(index: number, id: number) {
    const list = [];
    this.dialogFormVisible = true;
    const res = await getOneDetail(id);
    console.log("res.data", res.data);

    this.detailList = await res.data;
    list.push(this.detailList);
    this.arrDetailList = list;
  }
  // 对时间格式进行切割
  public spliceTime(time: string): string | void {
    const arr = time ? time.split(" ") : "";
    return arr[0];
  }
  // 点击左右箭头
  public handleSizeChange(val: any): void {
    this.form.size = val;
    this.getReportList();
  }
  // 点击页码
  public handleCurrentChange(val: any): void {
    this.form.current = val;
    this.getReportList();
  }
  /** 清空机构名称事件 */
  public clearName() {
    this.form.tenantId = "";
  }

  //签名base64
  public baseUrl = "";
  //是否打开签名框
  public showSig = false;

  //打开签名查看
  public opensig(data: any) {
    console.log("data", data);
    getSignature(data.id).then((res) => {
      console.log("请求成功", res);
      if (res.data.getSignature === "") {
        console.log("该报告暂无签名");
        this.$message({
          message: "该报告暂无签名",
          type: "warning",
        });
      } else {
        this.showSig = true;
        this.baseUrl = res.data.getSignature;
      }
    });
  }
}
